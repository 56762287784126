
import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import { IOrderForm, IXdocPlanning, IFileList } from '@/types/placeOrderTypes';
import { IServiceMap } from '@/business/BusinessFormData';
import FbdStyleLibrary from './FbdStyleLibrary.vue';
import { getFbdStyleConfig } from '@/api/Allservices';
import { cloneDeep } from 'lodash';

@Component({
  components: {
    FbdStyleLibrary
  }
})
export default class XdocProjectPlan extends Vue {
  @Prop() form!: IOrderForm;
  @Prop() checkForm!: Function;
  @Prop() formInfo!: IServiceMap;
  @Prop() titleList!: string[];
  Visible = false;
  inputVisible = false;
  fbdVisible = false;
  pdfVisible = false;
  dynamicTags: { name: string; canClose: boolean; select: boolean }[] = [
    { name: '教师用书', canClose: false, select: false },
  ];
  inputValue = '';
  projects: IXdocPlanning[] = [];
  currProject: IXdocPlanning = { name: '', config: { fbd_type: 1, delivery: 0 } };
  currFbdType = 'text';
  pdfList: IFileList[] = [];
  zipList: IFileList[] = [];
  checkPdfs: string[] = [];

  get xdocFbdList () {
    const arr = this.zipList.map(item => {
      return {
        id: item.id,
        zipName: item.zip!.name,
        fbdList: (item.fbdList || []).map(item => { return { name: item.name, select: false }; })
      };
    });
    return arr;
  }

  get xdocProjects () {
    return this.projects.map(item => {
      item.origin_files = (item.origin_files || []).map(item => {
        item = { ...item, selected: false };
        return item;
      });
      item.compare_pdf = (item.compare_pdf || []).map(item => {
        item = { ...item, selected: false };
        return item;
      });
      return item;
    });
  }

  @Watch('form', { deep: true })
  async formChange () {
    await this.init();
  }

  async mounted () {
    await this.init();
  }

  init () {
    this.pdfList = this.form.material.oss_pdf_files.map(item => {
      return {
        pdf: { name: item.name },
        status: 'upload-success'
      };
    });
    this.zipList = this.form.material.oss_fbd_zips.map(item => {
      return {
        zip: { name: item.name },
        fbdList: item.fbd_info,
        status: 'upload-success'
      };
    });
    if (this.form && this.form.xdoc_planning) {
      this.projects = this.form.xdoc_planning;
    }
  }

  getXdocPlanTitle () {
    let title = '';
    switch (this.form.service_content) {
    case 3:
      title = '书中哪些部分要转为word？（点击多选）';
      break;
    case 5:
      title = '书中哪些部分要进行数字化？（点击多选）';
      break;
    default:
      title = '书中哪些部分要制作课件？（点击多选）';
      break;
    }
    return title;
  }

  VisibleClickHandle () {
    if (this.checkForm(this.form)) return;
    this.Visible = true;
  }

  handleClose (tag: { name: string; canClose: boolean; select: boolean }) {
    this.dynamicTags.splice(this.dynamicTags.indexOf(tag), 1);
  }

  showInput () {
    this.inputVisible = true;
    this.$nextTick(() => {
      (this.$refs.saveTagInput as any).$refs.input.focus();
    });
  }

  handleInputConfirm () {
    const inputValue = this.inputValue;
    const item = this.dynamicTags.find(item => item.name === inputValue);
    if (item) {
      item.select = true;
      this.inputVisible = false;
      this.inputValue = '';
      return;
    }
    if (inputValue) {
      this.dynamicTags.push({ name: inputValue, canClose: true, select: true });
    }
    this.inputVisible = false;
    this.inputValue = '';
  }

  creatXdocPlanning () {
    const projects = this.dynamicTags.filter(item => item.select).map(item => {
      if (this.formInfo.category === 'digitization') {
        if (item.name === '教师用书正文') {
          return { name: this.form.alias + '-' + item.name, config: { fbd_type: 0, delivery: 3 }, origin_files: [], compare_pdf: [] };
        }
        return { name: this.form.alias + '-' + item.name, config: { fbd_type: 1, delivery: 3 }, origin_files: [], compare_pdf: [] };
      } else if (this.formInfo.category === 'dataConversion') {
        if (item.name === '教师用书正文') {
          return { name: this.form.alias + '-' + item.name, config: { fbd_type: 0, delivery: 1 }, origin_files: [], compare_pdf: [] };
        }
        return { name: this.form.alias + '-' + item.name, config: { fbd_type: 1, delivery: 1 }, origin_files: [], compare_pdf: [] };
      } else if (this.formInfo.category === 'studentToTeacher') {
        if (item.name === '教师用书正文') {
          return { name: this.form.alias + '-' + item.name, config: { fbd_type: 1, delivery: 8 }, origin_files: [], compare_pdf: [] };
        }
        return { name: this.form.alias + '-' + item.name, config: { fbd_type: 1, delivery: 8 }, origin_files: [], compare_pdf: [] };
      } else {
        if (item.name === '教师用书正文') {
          return { name: this.form.alias + '-' + item.name, config: { fbd_type: 0, delivery: 0 }, origin_files: [], compare_pdf: [] };
        }
        return { name: this.form.alias + '-' + item.name, config: { fbd_type: 1, delivery: 0 }, origin_files: [], compare_pdf: [] };
      }
    });
    const newProjects: IXdocPlanning[] = [];
    projects.forEach(pro => {
      const project = this.projects.find(item => item.name === pro.name);
      if (project) {
        newProjects.push(project);
      } else {
        newProjects.push(pro);
      }
    });
    newProjects.forEach(pro => {
      (pro.config!.stu2tea_title as string[]) = this.titleList;
    });
    this.form.xdoc_planning = newProjects;
    console.log(this.form.xdoc_planning);
    this.Visible = false;
  }

  cancleDialog () {
    this.Visible = false;
    this.inputVisible = false;
    this.fbdVisible = false;
    this.pdfVisible = false;
    this.inputValue = '';
    this.configVisible = false;
    this.xdocFbdList.forEach(item => item.fbdList.forEach((item: { name: string; select: boolean }) => {
      item.select = false;
    }));
    this.$forceUpdate();
    this.checkPdfs = [];
  }

  getOriginFilesName (file: string) {
    const name = file.split('/')[file.split('/').length - 1];
    const nameArr = name.split('.');
    nameArr[nameArr.length - 1] = nameArr[nameArr.length - 1].toLowerCase();
    return nameArr.join('.');
  }

  handleFbdsChange (val: boolean, item: { fbdList: { name: string; select: boolean }[] }, fbd: { name: string; select: boolean }) {
    item.fbdList.forEach(item => {
      if (item === fbd) this.$set(item, 'select', val);
      else this.$set(item, 'select', false);
    });
    // 由于计算属性的引用不会更新，所以需要强制刷新一次视图
    this.$forceUpdate();
  }

  projectFbdChange () {
    let textArr = this.currProject.origin_files!.filter(item => item.type === 1);
    let answerArr = this.currProject.origin_files!.filter(item => item.type === 0);
    const origin_files: { index: number; type: number; name: string; fbd_zip_name?: string }[] = [];
    for (const item of this.xdocFbdList) {
      item.fbdList.forEach((fbd: { name: string; select: boolean }) => {
        if (fbd.select) {
          origin_files.push({ index: origin_files.length, type: (this.currFbdType === 'text' ? 1 : 0), name: this.getOriginFilesName(fbd.name).split('.fbd').join(''), fbd_zip_name: item.zipName });
        }
      });
    }
    if (this.currFbdType === 'text') {
      textArr = origin_files;
    } else {
      answerArr = origin_files;
    }
    this.$set(this.currProject, 'origin_files', answerArr.concat(textArr));
    this.cancleDialog();
  }

  projectPdfChange () {
    let textArr = this.currProject.compare_pdf!.filter(item => item.type === 1);
    let answerArr = this.currProject.compare_pdf!.filter(item => item.type === 0);
    if (this.currFbdType === 'text') {
      textArr = this.checkPdfs.map(item => {
        return { type: 1, name: item, path: this.form.material.oss_pdf_files!.find(pdf => pdf!.name.includes(item))!.name };
      });
    } else {
      answerArr = this.checkPdfs.map(item => {
        return { type: 0, name: item, path: this.form.material.oss_pdf_files!.find(pdf => pdf!.name.includes(item))!.name };
      });
    }
    this.$set(this.currProject, 'compare_pdf', answerArr.concat(textArr));
    this.cancleDialog();
  }

  deleteProjectPdf (type: string, pdfname: string, projectIndex: number) {
    const answerArr = (this.projects[projectIndex].compare_pdf || []).filter(item => item.type === 0);
    const textArr = (this.projects[projectIndex].compare_pdf || []).filter(item => item.type === 1);
    if (type === 'text') {
      const index = textArr!.findIndex(item => item.name === pdfname);
      textArr.splice(index, 1);
    } else {
      const index = answerArr!.findIndex(item => item.name === pdfname);
      answerArr.splice(index, 1);
    }
    this.$set(this.projects[projectIndex], 'compare_pdf', answerArr.concat(textArr));
  }

  deleteProjectFbd (type: string, index: number, projectIndex: number) {
    let answerArr = (this.projects[projectIndex].origin_files || []).filter(item => item.type === 0);
    let textArr = (this.projects[projectIndex].origin_files || []).filter(item => item.type === 1);
    if (type === 'text') {
      textArr.splice(index, 1);
    } else {
      answerArr.splice(index, 1);
    }
    answerArr = answerArr.map((item, index) => {
      item.index = index;
      item.type = 0;
      return item;
    });
    textArr = textArr.map((item, index) => {
      item.index = index;
      item.type = 1;
      return item;
    });
    this.$set(this.projects[projectIndex], 'origin_files', answerArr.concat(textArr));
  }

  fbdMoveDownChange (type: string, index: number, projectIndex: number) {
    let answerArr = (this.projects[projectIndex].origin_files || []).filter(item => item.type === 0);
    let textArr = (this.projects[projectIndex].origin_files || []).filter(item => item.type === 1);
    if (type === 'text') {
      if (index === textArr.length) return;
      const item = textArr.find(item => item.index === index);
      if (item) {
        textArr.splice(index, 1);
        textArr.splice(index + 1, 0, item);
      }
    } else {
      if (index === answerArr.length) return;
      const item = answerArr.find(item => item.index === index);
      if (item) {
        answerArr.splice(index, 1);
        answerArr.splice(index + 1, 0, item);
      }
    }
    answerArr = answerArr.map((item, index) => {
      item.index = index;
      item.type = 0;
      return item;
    });
    textArr = textArr.map((item, index) => {
      item.index = index;
      item.type = 1;
      return item;
    });
    this.$set(this.projects[projectIndex], 'origin_files', answerArr.concat(textArr));
  }

  fbdMoveUpChange (type: string, index: number, projectIndex: number) {
    let answerArr = (this.projects[projectIndex].origin_files || []).filter(item => item.type === 0);
    let textArr = (this.projects[projectIndex].origin_files || []).filter(item => item.type === 1);
    if (type === 'text') {
      if (index === 0) return;
      const item = textArr.find(item => item.index === index);
      if (item) {
        textArr.splice(index, 1);
        textArr.splice(index - 1, 0, item);
      }
    } else {
      if (index === 0) return;
      const item = answerArr.find(item => item.index === index);
      if (item) {
        answerArr.splice(index, 1);
        answerArr.splice(index - 1, 0, item);
      }
    }
    answerArr = answerArr.map((item, index) => {
      item.index = index;
      item.type = 0;
      return item;
    });
    textArr = textArr.map((item, index) => {
      item.index = index;
      item.type = 1;
      return item;
    });
    this.$set(this.projects[projectIndex], 'origin_files', answerArr.concat(textArr));
  }

  initSelectFbd () {
    this.currProject.origin_files!.forEach(item => {
      const xdoc = this.xdocFbdList.find(xdoc => item.fbd_zip_name === xdoc.zipName);
      const fbd = xdoc!.fbdList.find((fbd: { name: string; select: boolean }) => fbd.name.includes(item.name) && item.type === (this.currFbdType === 'text' ? 1 : 0));
      if (fbd) {
        this.$set(fbd, 'select', true);
      }
    });
    if (this.currFbdType === 'text') {
      this.checkPdfs = this.currProject.compare_pdf!.filter(item => item.type === 1).map(item => item.name);
    } else {
      this.checkPdfs = this.currProject.compare_pdf!.filter(item => item.type === 0).map(item => item.name);
    }
  }

  fbdTypeChangeHandle (val: number, project: IXdocPlanning) {
    if (!val) {
      project.compare_pdf = project.compare_pdf!.filter(item => item.type === 1);
      project.origin_files = project.origin_files!.filter(item => item.type === 1);
    }
  }

  getZipName (zipName: string) {
    const arr = zipName.split('');
    const str = arr[0] + arr[1] + '...' + arr[arr.length - 6] + arr[arr.length - 5] + '.zip';
    return str;
  }

  // 添加配置
  configVisible = false
  configProject: IXdocPlanning | null = null

  openStyleLibrary (project: IXdocPlanning) {
    this.configProject = project;
    this.configVisible = true;
    // 保存一下表单数据
    this.$emit('saveForm');
  }

  clearConfig () {
    this.configProject = null;
  }

  copyConfig (pIndex: number) {
    const prevProjectConfig = this.xdocProjects[pIndex - 1].config.stu2tea_config;
    const prevProjectConfigName = this.xdocProjects[pIndex - 1].config.stu2tea_config_name;
    if (prevProjectConfig && prevProjectConfigName) {
      this.$set(this.xdocProjects[pIndex].config, 'stu2tea_config', cloneDeep(prevProjectConfig));
      this.$set(this.xdocProjects[pIndex].config, 'stu2tea_config_name', cloneDeep(prevProjectConfigName));
    }
  }

  async handleFbdConfigSave (style_id: number) {
    const config = await getFbdStyleConfig({
      style_id
    });
    if (this.configProject) {
      this.$set(this.configProject.config, 'stu2tea_config', config.config);
      this.$set(this.configProject.config, 'stu2tea_config_name', config.name);
    }
    this.configVisible = false;
  }

  saveTitleList (titleList: any) {
    this.$emit('saveTitleList', titleList);
  }
};
