
// @ts-ignore
import CircleProgress from 'vue-circleprogressbar';
// @ts-ignore
import Oss from 'ali-oss';
import { Component, Vue, Watch, Prop } from 'vue-property-decorator';
import { selectFile } from '@/utils/FileApis';
import { uuid } from '@/utils/uuid';
import { getFileMd5, handleUrl } from '../helper';
import { getOssInfo, creatOrder, editOrder, getMaterialFile, creatMaterialFile, creatMaterialFileSymlink, setUploadHistory } from '@/api/placeOrder';
import { IOrderForm, IFileList } from '@/types/placeOrderTypes';
import { Action, Getter } from 'vuex-class';

@Component({
  components: {
    CircleProgress,
  }
})
export default class UploadMaterial extends Vue {
  @Prop() form!: IOrderForm;
  @Prop() checkForm!: Function;
  @Prop() screenForm!: Function;
  @Action('setUploading') setUploading: any;
  @Getter('uploading') uploading: any;
  @Action('setOrderId') setOrderId: any;
  @Getter('order_id') order_id: any;
  @Getter('user') user: any;
  files: IFileList[] = [];
  fileList: IFileList[] = [];
  checking = false;

  @Watch('files')
  async onFbdFilesChange () {
    this.checking = true;
    for (const item of this.files) {
      if (item.other) {
        if (item.status === 'checking') {
          this.fileList.push(item);
          if (!item.md5) {
            item.md5 = await getFileMd5(item.other as File) as string;
            item.status = 'init';
          }
        }
      }
    }
    this.checking = false;
  }

  @Watch('form')
  async formChange () {
    await this.init();
  }

  async mounted () {
    await this.init();
  }

  init () {
    if (this.form && this.form.material.courseware_sample_files) {
      this.fileList = this.form.material.courseware_sample_files.map(item => {
        return {
          other: { name: item.name },
          status: 'upload-success'
        };
      });
    }
  }

  sizeFilter (bytesize: number) {
    let i = 0;
    // 当bytesize 大于是1024字节时，开始循环，当循环到第4次时跳出；
    while (Math.abs(bytesize) >= 1024) {
      bytesize = bytesize / 1024;
      i++;
      if (i === 4) break;
    }
    // 将Bytes,KB,MB,GB,TB定义成一维数组；
    const units = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
    const newsize = bytesize.toFixed(2);
    return newsize + ' ' + units[i];
  }

  selectTemplateFile () {
    selectFile({
      multiple: true
    }, async (files) => {
      if (files.some(file => !/^((?!\\|\/|:|\*|\?|"|<|>|\||').)*$/g.test(file.name))) {
        this.$message.warning('存在非法字符\\ / : * ? " < > | \' ');
        return;
      }
      files = files.filter(f => {
        return !this.files.filter(item => item.other && item.other.name === f.name).length && !this.fileList.filter(item => item.other && f.name === item.other.name).length;
      });
      files.forEach((file) => {
        const item = {
          id: '',
          zip: null,
          pdf: null,
          other: null,
          status: 'checking',
          uploadInfo: '',
          progress: 0
        } as any;
        const uid = uuid();
        item.id = uid;
        this.files.push(item);
        item.other = file;
        item.status = 'checking';
      });
    });
  }

  async startUploadFile () {
    let order_id = this.order_id;
    if (!order_id) {
      if (this.checkForm(this.form)) return;
      const res = await creatOrder({
        ...this.form
      });
      order_id = res.ticket_id;
      (this as any).$updateQuery({
        id: order_id,
      });
      this.setOrderId(order_id);
    }
    if (this.uploading) return;
    this.setUploading(true);
    const auth = await getOssInfo({ ticket_id: this.order_id, path_alias: 'file' });
    const { access_key_id, access_key_secret_id, bucket, region, key } = auth;
    const fileList = this.files.filter(item => item.other && !this.form.material.courseware_sample_files.filter(other => other!.name === item.other!.name).length);
    if (!fileList.length) {
      this.$message.warning('请先选择一个文件');
      return;
    }
    for (const item of fileList) {
      try {
        item.status = 'uploadding';
        item.progress = 0;
        const material = await getMaterialFile({
          md5: item.md5!
        });
        const { path } = material;
        if (path) {
          if (this.form) {
            if (!this.form.material.courseware_sample_files) this.form.material.courseware_sample_files = [];
            const symlinkData = await creatMaterialFileSymlink({
              ticket_id: this.order_id,
              origin_path: path,
              name: item.other!.name,
              path_alias: 'courseware_sample'
            });
            this.form.material.courseware_sample_files.push({ path: handleUrl(symlinkData.symlink_path), is_del: false, extension: item.other!.name.split('.')[item.other!.name.split('.').length - 1], name: item.other!.name, type: 1, md5: item.md5 });
            item.progress = 100;
            item.status = 'upload-success';
            continue;
          }
        }
        const client = new Oss({ region, accessKeyId: access_key_id, accessKeySecret: access_key_secret_id, bucket });
        await client.multipartUpload(`${key}/${item.md5}.${item.other!.name.split('.')[item.other!.name.split('.').length - 1]}`, item.other, {
          parallel: 5,
          partSize: 1024 * 1024,
          timeout: 4000000,
          progress: function (p: number) {
            item.progress = +(p * 100).toFixed(2);
          }
        });
        await creatMaterialFile({
          md5: item.md5!,
          name: item.other!.name,
          extension: item.other!.name.split('.')[item.other!.name.split('.').length - 1],
          path: `${key}/${item.md5}.${item.other!.name.split('.')[item.other!.name.split('.').length - 1]}`
        });
        const symlinkData = await creatMaterialFileSymlink({
          ticket_id: this.order_id,
          origin_path: `${key}/${item.md5}.${item.other!.name.split('.')[item.other!.name.split('.').length - 1]}`,
          name: item.other!.name,
          path_alias: 'courseware_sample'
        });
        if (this.form) {
          if (!this.form.material.courseware_sample_files) this.form.material.courseware_sample_files = [];
          this.form.material.courseware_sample_files.push({ path: handleUrl(symlinkData.symlink_path), is_del: false, extension: item.other!.name.split('.')[item.other!.name.split('.').length - 1], name: item.other!.name, type: 1, md5: item.md5 });
        }
        item.status = 'upload-success';
      } catch (e) {
        await setUploadHistory({
          user_id: this.user.userId,
          username: this.user.nickname,
          ticket_id: this.order_id,
          type: 9999,
          ext: {
            data: `${item.other!.name} 上传失败！请检查该文件的MD5值是否存在${item.md5}`
          }
        });
        item.status = 'upload-fail';
        item.uploadInfo = e;
        item.progress = 0;
      }
    }
    await editOrder({ ticket_id: this.order_id, ...this.screenForm(this.form) });
    this.setUploading(false);
  }

  removeOtherFile (name: string) {
    const index = this.files.findIndex((item) => item.other && item.other.name === name);
    const ListIndex = this.fileList.findIndex((item) => item.other && item.other.name === name);
    this.files.splice(index, 1);
    this.fileList.splice(ListIndex, 1);
    if (this.form && this.form.material.courseware_sample_files && this.form.material.courseware_sample_files.filter(item => item.name === name).length) {
      this.form.material.courseware_sample_files.splice(ListIndex, 1);
    }
  }

  downloadOther (name: string) {
    if (this.form && this.form.material && this.form.material.courseware_sample_files) {
      const file = this.form.material.courseware_sample_files.find(item => item.name === name);
      const a = document.createElement('a');
      let path = '';
      if (file!.path?.includes('+')) {
        // const arr = file!.path!.split('/');
        // arr[arr.length - 1] = encodeURIComponent(arr[arr.length - 1]);
        // path = arr.join('/');
        path = file!.path!.replaceAll('+', '%2B');
      } else {
        path = file!.path!;
      }
      a.href = `https://hexin-worksheet.oss-cn-shanghai.aliyuncs.com/${path}`;
      a.click();
    }
  }
};
