
import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import { IOrderForm, IXdocPlanning, IFileList } from '@/types/placeOrderTypes';
import { IServiceMap } from '@/business/BusinessFormData';
import { getStyleConfig } from '@/api/Allservices';
import FbdStyleLibrary from './FbdStyleLibrary.vue';
import styleDemo from '../../StyleDemoV2/index.vue';

@Component({
  components: {
    FbdStyleLibrary,
    styleDemo
  }
})
export default class XdocProjectPlanForWord extends Vue {
  @Prop() form!: IOrderForm;
  @Prop() checkForm!: Function;
  @Prop() formInfo!: IServiceMap;
  Visible = false;
  inputVisible = false;
  fbdVisible = false;
  fbdConfigVisible = false;
  fbdConfigEditVisible = false;
  pdfVisible = false;
  dynamicTags: { name: string; canClose: boolean; select: boolean }[] = [
    { name: '学生用书正文', canClose: false, select: false },
    { name: '教师用书正文', canClose: false, select: false },
    { name: '试卷', canClose: false, select: false },
    { name: '练习册', canClose: false, select: false },
  ];
  inputValue = '';
  projects: IXdocPlanning[] = [];
  currProject: IXdocPlanning = { name: '', config: { fbd_type: 1, delivery: 0 } };
  currFbdType = 'text';
  pdfList: IFileList[] = [];
  wordList: IFileList[] = [];
  checkPdfs: string[] = [];

  get xdocWordList () {
    const arr = this.wordList.map(item => {
      return {
        id: item.id,
        wordList: ([item.word] || []).map(item => { return { name: item!.name, select: false }; }),
        wordName: item.word?.name,
      };
    });
    return arr;
  }

  get xdocProjects () {
    return this.projects.map(item => {
      item.origin_files = (item.origin_files || []).map(item => {
        item = { ...item, selected: false };
        return item;
      });
      item.compare_pdf = (item.compare_pdf || []).map(item => {
        item = { ...item, selected: false };
        return item;
      });
      return item;
    });
  }

  @Watch('form', { deep: true })
  async formChange () {
    await this.init();
  }

  async mounted () {
    await this.init();
    this.$nextTick(() => {
      if ((this as any).$refs.styleButton && this.$route.query.reOpen) {
        (this as any).$refs.styleButton[0].$el.click();
      }
    });
  }

  init () {
    this.wordList = this.form.material.oss_word_files!.map(item => {
      return {
        word: { name: item.name },
        status: 'upload-success'
      };
    });
    if (this.form && this.form.xdoc_planning) {
      this.projects = this.form.xdoc_planning;
      this.currProject = this.projects[0];
    }
    if ([11, 16].includes(this.formInfo.formData.service_content)) {
      this.dynamicTags = [
        { name: '图书', canClose: false, select: false },
        { name: '练习册', canClose: false, select: false },
      ];
    }
    (this as any).$updateQuery({
      fbd_config_visible: '0',
    });
  }

  getXdocPlanTitle () {
    let title = '';
    switch (this.form.service_content) {
    case 3:
      title = '书中哪些部分要转为word？（点击多选）';
      break;
    case 5:
      title = '书中哪些部分要进行数字化？（点击多选）';
      break;
    default:
      title = '书中哪些部分要制作课件？（点击多选）';
      break;
    }
    return title;
  }

  VisibleClickHandle () {
    if (this.checkForm(this.form)) return;
    this.Visible = true;
  }

  handleClose (tag: { name: string; canClose: boolean; select: boolean }) {
    this.dynamicTags.splice(this.dynamicTags.indexOf(tag), 1);
  }

  showInput () {
    this.inputVisible = true;
    this.$nextTick(() => {
      (this.$refs.saveTagInput as any).$refs.input.focus();
    });
  }

  handleInputConfirm () {
    const inputValue = this.inputValue;
    const item = this.dynamicTags.find(item => item.name === inputValue);
    if (item) {
      item.select = true;
      this.inputVisible = false;
      this.inputValue = '';
      return;
    }
    if (inputValue) {
      this.dynamicTags.push({ name: inputValue, canClose: true, select: true });
    }
    this.inputVisible = false;
    this.inputValue = '';
  }

  creatXdocPlanning () {
    const projects = this.dynamicTags.filter(item => item.select).map(item => {
      if (this.formInfo.category === 'digitization') {
        if (item.name === '教师用书正文') {
          return { name: this.form.alias + '-' + item.name, config: { fbd_type: 0, delivery: 3 }, origin_files: [], compare_pdf: [] };
        }
        return { name: this.form.alias + '-' + item.name, config: { fbd_type: 1, delivery: 3 }, origin_files: [], compare_pdf: [] };
      } else if (this.formInfo.category === 'dataConversion') {
        if ([11, 16].includes(this.formInfo.formData.service_content)) {
          return { name: this.form.alias + '-' + item.name, config: { fbd_type: 0, delivery: 9 }, origin_files: [], compare_pdf: [] };
        }
        if (item.name === '教师用书正文') {
          return { name: this.form.alias + '-' + item.name, config: { fbd_type: 0, delivery: 1 }, origin_files: [], compare_pdf: [] };
        }
        return { name: this.form.alias + '-' + item.name, config: { fbd_type: 1, delivery: 1 }, origin_files: [], compare_pdf: [] };
      } else {
        if (item.name === '教师用书正文') {
          return { name: this.form.alias + '-' + item.name, config: { fbd_type: 0, delivery: 0 }, origin_files: [], compare_pdf: [] };
        }
        return { name: this.form.alias + '-' + item.name, config: { fbd_type: 1, delivery: 0 }, origin_files: [], compare_pdf: [] };
      }
    });
    const newProjects: IXdocPlanning[] = [];
    projects.forEach(pro => {
      const project = this.projects.find(item => item.name === pro.name);
      if (project) {
        newProjects.push(project);
      } else {
        newProjects.push(pro);
      }
    });
    this.$set(this.form, 'xdoc_planning', newProjects);
    this.Visible = false;
  }

  cancleDialog () {
    this.Visible = false;
    this.inputVisible = false;
    this.fbdVisible = false;
    (this as any).$updateQuery({
      fbd_config_visible: '0',
    });
    this.pdfVisible = false;
    this.inputValue = '';
    this.xdocWordList.forEach(item => item.wordList.forEach((item: { name: string; select: boolean }) => {
      item.select = false;
    }));
    this.$forceUpdate();
    this.checkPdfs = [];
  }

  getOriginFilesName (file: string) {
    const name = file.split('/')[file.split('/').length - 1];
    const nameArr = name.split('.');
    nameArr[nameArr.length - 1] = nameArr[nameArr.length - 1].toLowerCase();
    return nameArr.join('.');
  }

  wordCheckAll = false
  handleCheckAllChange (val: boolean) {
    this.xdocWordList.map(item => {
      item.wordList.map(word => {
        this.handleFbdsChange(val, item, word);
      });
    });
    this.wordCheckAll = val;
  }

  handleFbdsChange (val: boolean, item: { wordList: { name: string; select: boolean }[] }, word: { name: string; select: boolean }) {
    this.$set(word, 'select', val);
    // 由于计算属性的引用不会更新，所以需要强制刷新一次视图
    this.$forceUpdate();
    const arr: string[] = item.wordList.filter(item => item.select).map(item => {
      return this.getOriginFilesName(item.name);
    });
    function isRepeat (arr: string[]) {
      const hash: any = {};
      for (const i in arr) {
        if (hash[arr[i]]) return true;
        hash[arr[i]] = true;
      }
      return false;
    }
    if (isRepeat(arr)) {
      return this.$message.error('请勿选择同名word文件');
    } else {
      this.$set(item, 'checkFbdList', arr);
    }
  }

  deleteProjectFbd (type: string, index: number, projectIndex: number) {
    let answerArr = (this.projects[projectIndex].origin_files || []).filter(item => item.type === 0);
    let textArr = (this.projects[projectIndex].origin_files || []).filter(item => item.type === 1);
    if (type === 'text') {
      textArr.splice(index, 1);
    } else {
      answerArr.splice(index, 1);
    }
    answerArr = answerArr.map((item, index) => {
      item.index = index;
      item.type = 0;
      return item;
    });
    textArr = textArr.map((item, index) => {
      item.index = index;
      item.type = 1;
      return item;
    });
    this.$set(this.projects[projectIndex], 'origin_files', answerArr.concat(textArr));
  }

  projectFbdChange () {
    let textArr = this.currProject.origin_files!.filter(item => item.type === 1);
    let answerArr = this.currProject.origin_files!.filter(item => item.type === 0);
    const origin_files: { index: number; type: number; name: string; fbd_zip_name?: string }[] = [];
    for (const item of this.xdocWordList) {
      item.wordList.forEach((fbd: { name: string; select: boolean }) => {
        if (fbd.select) {
          origin_files.push({ index: origin_files.length, type: (this.currFbdType === 'text' ? 1 : 0), name: this.getOriginFilesName(fbd.name).split('.fbd').join('') });
        }
      });
    }
    if (this.currFbdType === 'text') {
      textArr = origin_files;
    } else {
      answerArr = origin_files;
    }
    this.$set(this.currProject, 'origin_files', answerArr.concat(textArr));
    this.cancleDialog();
  }

  fbdMoveDownChange (type: string, index: number, projectIndex: number) {
    let answerArr = (this.projects[projectIndex].origin_files || []).filter(item => item.type === 0);
    let textArr = (this.projects[projectIndex].origin_files || []).filter(item => item.type === 1);
    if (type === 'text') {
      if (index === textArr.length) return;
      const item = textArr.find(item => item.index === index);
      if (item) {
        textArr.splice(index, 1);
        textArr.splice(index + 1, 0, item);
      }
    } else {
      if (index === answerArr.length) return;
      const item = answerArr.find(item => item.index === index);
      if (item) {
        answerArr.splice(index, 1);
        answerArr.splice(index + 1, 0, item);
      }
    }
    answerArr = answerArr.map((item, index) => {
      item.index = index;
      item.type = 0;
      return item;
    });
    textArr = textArr.map((item, index) => {
      item.index = index;
      item.type = 1;
      return item;
    });
    this.$set(this.projects[projectIndex], 'origin_files', answerArr.concat(textArr));
  }

  fbdMoveUpChange (type: string, index: number, projectIndex: number) {
    let answerArr = (this.projects[projectIndex].origin_files || []).filter(item => item.type === 0);
    let textArr = (this.projects[projectIndex].origin_files || []).filter(item => item.type === 1);
    if (type === 'text') {
      if (index === 0) return;
      const item = textArr.find(item => item.index === index);
      if (item) {
        textArr.splice(index, 1);
        textArr.splice(index - 1, 0, item);
      }
    } else {
      if (index === 0) return;
      const item = answerArr.find(item => item.index === index);
      if (item) {
        answerArr.splice(index, 1);
        answerArr.splice(index - 1, 0, item);
      }
    }
    answerArr = answerArr.map((item, index) => {
      item.index = index;
      item.type = 0;
      return item;
    });
    textArr = textArr.map((item, index) => {
      item.index = index;
      item.type = 1;
      return item;
    });
    this.$set(this.projects[projectIndex], 'origin_files', answerArr.concat(textArr));
  }

  initSelectFbd () {
    this.currProject.origin_files!.forEach(item => {
      const xdoc = this.xdocWordList.find(xdoc => item.name === xdoc.wordName);
      const fbd = xdoc!.wordList.find((fbd: { name: string; select: boolean }) => fbd.name.includes(item.name) && item.type === (this.currFbdType === 'text' ? 1 : 0));
      if (fbd) {
        this.$set(fbd, 'select', true);
        // (xdoc!.checkFbdList as string[]).push(item.name + '.fbd');
      }
    });
    if (this.currFbdType === 'text') {
      this.checkPdfs = this.currProject.compare_pdf!.filter(item => item.type === 1).map(item => item.name);
    } else {
      this.checkPdfs = this.currProject.compare_pdf!.filter(item => item.type === 0).map(item => item.name);
    }
  }

  fbdTypeChangeHandle (val: number, project: IXdocPlanning) {
    if (!val) {
      project.compare_pdf = project.compare_pdf!.filter(item => item.type === 1);
      project.origin_files = project.origin_files!.filter(item => item.type === 1);
    }
  }

  // getZipName (zipName: string) {
  //   // const arr = zipName.split('');
  //   // const str = arr[0] + arr[1] + '...' + arr[arr.length - 6] + arr[arr.length - 5] + '.zip';
  //   // return str;
  // }
  async openStyleLibrary (project: IXdocPlanning) {
    (this as any).$updateQuery({
      fbd_config_visible: '1',
    });
    this.currProject = project;
    // 保存一下表单数据
    this.$emit('saveForm');
  }

  async handleFbdConfigSave (style_id: number) {
    const config = await getStyleConfig({
      style_id
    });
    this.$set(this.currProject.config, 'style_config_data', config.config);
    if (config.type) {
      this.$set(this.currProject.config, 'word_type', config.type);
    } else {
      this.$set(this.currProject.config, 'word_type', 'paper');
    }
    this.$set(this.currProject.config, 'style_config_name', config.name);
    this.$set(this.currProject.config, 'style_id', config._id);
    (this as any).$updateQuery({
      fbd_config_visible: '0',
    });
  }

  handleFbdConfigEditSave (config: any) {
    this.$set(this.currProject.config, 'style_config_data', config.config);
    this.$set(this.currProject.config, 'style_config_name', config.name);
    this.fbdConfigEditVisible = false;
  }

  @Watch('$route.query.fbd_config_visible', { deep: true, immediate: true })
  async fbdConfigVisibleChange () {
    this.fbdConfigVisible = (this.$route.query.fbd_config_visible === '1');
  }
};
