
import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import { selectFile } from '@/utils/FileApis';
// import { uuid } from '@/utils/uuid';
// @ts-ignore
import * as uuid from 'uuid/v4';
import { IOrderForm, IXdocPlanning } from '@/types/placeOrderTypes';
import { creatOrder, getOssInfo, editOrder } from '@/api/placeOrder';
// @ts-ignore
import Oss from 'ali-oss';
import { Action, Getter } from 'vuex-class';
type TFileStatus = 'init' | 'ing' | 'success' | 'fail' | 'error' | 'warning';
@Component({})
export default class OrderContentProcess extends Vue {
  @Prop() checkForm!: Function;
  @Prop() form?: IOrderForm;
  @Prop() disable?: boolean;
  @Prop() isProject?: boolean;
  @Prop() isDigitization?: boolean;
  @Prop() target?: 'word' | 'fbd';
  @Prop() editflag?: boolean;
  @Action('setUploading') setUploading: any;
  @Getter('uploading') uploading: any;
  @Getter('order_id') order_id: any;
  @Action('setOrderId') setOrderId: any;
  projects: IXdocPlanning[] = [];
  // order_id = '';
  files: { image?: File; id?: string; progress: number; status?: TFileStatus }[] = [];

  // @Watch('$route', { immediate: true, deep: true })
  // getId () {
  //   this.order_id = this.$route.query.id as string;
  // }

  @Watch('form')
  async formChange () {
    await this.init();
  }

  async mounted () {
    await this.init();
  }

  get unUploadImgs () {
    if (this.form && this.form.service_content === 17) {
      const uploadImgs = this.form.material.oss_images?.filter((item: any) => !item.isDel);
      const unUploadImgs = this.form.xdoc_planning?.[0]?.origin_files?.filter((item: any) => !item.isDel);
      if (unUploadImgs?.length) {
        return unUploadImgs.length - (uploadImgs?.length || 0);
      } else {
        return 0;
      }
    }
    return 0;
  }

  init () {
    if (this.form && this.form.xdoc_planning.length && this.form.service_content !== 5) {
      this.form.xdoc_planning.forEach(item => {
        item.suffix = item.name.split('-')[item.name.split('-').length - 1];
      });
      this.projects = this.form.xdoc_planning;
    } else {
      this.projects = [
        {
          name: this.form!.alias + '-任务1',
          origin_files: [],
          is_del: false,
          suffix: '',
          config: {
            delivery: this.isDigitization ? 3 : this.target === 'word' ? this.form?.service_content === 17 ? 14 : 1 : 4
          }
        }
      ];
      this.form!.xdoc_planning = this.projects;
    }
  }

  selectImage (project: IXdocPlanning) {
    selectFile({
      accept: this.form?.service_content === 17 ? '.jpg, .jpeg, .png, .tif' : 'image/png,image/jpeg',
      multiple: true
    }, async (files) => {
      if (files.some(file => !/^((?!\\|\/|:|\*|\?|"|<|>|\||').)*$/g.test(file.name))) {
        this.$message.warning('存在非法字符\\ / : * ? " < > | \' ');
        return;
      }
      files = files.filter(f => /\.(png|jpe?g|tif)$/i.test(f.name));
      if (!files.length) {
        this.$message.warning('请上传以.png、.jpg、.jpeg、.tif后缀格式文件！');
        return;
      }
      files = files.filter(f => {
        return !project.origin_files!.filter(item => item.name === f.name).length;
      });
      files.forEach((file) => {
        const item = {
          id: '',
          image: null,
          status: 'init',
          progress: 0
        } as any;
        const uid = uuid().replace(/-/g, '');
        item.id = uid;
        if (!this.files.filter(item => item.image && item.image.name === file.name).length) {
          this.files.push(item);
        }
        item.image = file;
        item.status = 'init';
        project.origin_files!.push({
          name: file.name,
          index: project.origin_files!.length,
          type: 1,
        });
      });
    });
  }

  async startUploadImage (project: IXdocPlanning) {
    let order_id = this.order_id;
    if (!order_id) {
      if (this.checkForm(this.form)) return;
      const res = await creatOrder({
        ...this.form
      } as IOrderForm);
      order_id = res.ticket_id;
      (this as any).$updateQuery({
        id: order_id,
      });
      this.setOrderId(order_id);
    }
    if (this.uploading) { return; }
    this.setUploading(true);
    let res;
    if (this.form!.service_content !== 17) res = await getOssInfo({ ticket_id: this.order_id, path_alias: 'content_image', xdoc_stable: true });
    else res = await getOssInfo({ ticket_id: this.order_id, path_alias: 'content_image' });
    const auth = res;
    const { access_key_id, access_key_secret_id, bucket, region } = auth;
    const imageList = this.files.filter(item => item.image && project.origin_files!.filter(image => image.name === item.image!.name).length);
    if (!imageList.length) {
      this.$message.warning('请先选择一个image文件');
      return;
    }
    for (const item of imageList) {
      if (item.status !== 'success') {
        try {
          item.status = 'ing';
          item.progress = 0;
          const client = new Oss({ region, accessKeyId: access_key_id, accessKeySecret: access_key_secret_id, bucket });
          await client.multipartUpload(`/open/${this.form!.app_key}/image/${item.id}.jpg`, item.image, {
            parallel: 4,
            partSize: 500 * 1024,
            timeout: 4000000,
            progress: function (p: number) {
              item.progress = +(p * 100).toFixed(2);
            }
          });
          if (this.form) {
            if (!this.form.material.oss_images) this.form.material.oss_images = [];
            this.form.material.oss_images.push({ path: `/open/${this.form!.app_key}/image/${item.id}.jpg`, is_del: false, name: item.image!.name });
          }
          item.status = 'success';
        } catch (e) {
          item.status = 'fail';
          item.progress = 0;
        }
      }
    }
    await editOrder({ ...this.form, ticket_id: this.order_id } as IOrderForm);
    this.setUploading(false);
    this.$message.success('上传成功');
  }

  checkError (str: string) {
    return !/^((?!\\|\/|:|\*|\?|"|<|>|\||').)*$/g.test(str);
  }

  suffixChange (value: string, project: IXdocPlanning) {
    if (this.form) {
      project.name = this.form!.alias + '-任务' + (this.projects.findIndex(item => item === project) + 1) + '-' + value.trim();
    }
  }

  createNewProject () {
    this.projects.push({
      name: this.form!.alias + '-任务' + (this.projects.length + 1),
      origin_files: [],
      is_del: false,
      suffix: '',
      config: {
        delivery: this.isDigitization ? 3 : this.target === 'word' ? 1 : 4
      }
    });
  }

  deleteProject (index: number) {
    this.projects.splice(index, 1);
    this.projects.forEach((item, index) => {
      item.name = this.form!.alias + '-任务' + (index + 1) + '-' + item.suffix;
    });
  }

  formatProgress (percentage: number) {
    return percentage === 100 ? '上传完成' : `${percentage}%`;
  }

  downloadImage (name: string) {
    if (this.form && this.form.material && this.form.material.oss_images) {
      const image = this.form.material.oss_images.find(item => item.name === name);
      const a = document.createElement('a');
      a.href = `https://xdoc-stable.oss-cn-shanghai.aliyuncs.com${image!.path}`;
      a.click();
    }
  }

  removeImageFile (name: string, project: IXdocPlanning) {
    const ListIndex = project.origin_files!.findIndex((item) => item && item.name === name);
    project.origin_files!.splice(ListIndex, 1);
    let fileList: string[] = [];
    this.projects.forEach(item => {
      fileList = fileList.concat(item.origin_files!.map(item => item.name));
    });
    fileList = Array.from(new Set(fileList));
    this.files = this.files.filter(item => fileList.includes(item.image!.name));
    this.form!.material.oss_images = this.form!.material.oss_images!.filter(item => fileList.filter(f => item.name === f).length);
  }

  imageMoveDownChange (index: number, projects: IXdocPlanning) {
    if (index === projects.origin_files!.length) return;
    const item = projects.origin_files!.find(item => item.index === index);
    if (item) {
      projects.origin_files!.splice(index, 1);
      projects.origin_files!.splice(index + 1, 0, item);
    }
    projects.origin_files!.forEach((item, index) => {
      item.index = index;
    });
  }

  imageMoveUpChange (index: number, projects: IXdocPlanning) {
    if (index === 0) return;
    const item = projects.origin_files!.find(item => item.index === index);
    if (item) {
      projects.origin_files!.splice(index, 1);
      projects.origin_files!.splice(index - 1, 0, item);
    }
    projects.origin_files!.forEach((item, index) => {
      item.index = index;
    });
  }
}
